import { useState } from "react";
import Smaphon2Col, { MainWidth } from "templates/Smaphon2Col";
import { styled } from "@mui/material/styles";
import { theme } from "theme/Smaphon2Col";
import { Address } from "components/Address";
import useDeviceType, { DeviceType } from "lib/useDeviceType";
import roomImage from "assets/images/room_01.jpg";
import profileTitleImage from "assets/images/profile_title.svg";
import menuTitleImage from "assets/images/menu_title.svg";
import yoyakuTitle02Image from "assets/images/yoyakujyokyo_title.svg";
import syozaitiImage from "assets/images/syozaiti.svg";
import mainImageWebp from "assets/images/main_image.webp";
import mainLogoImage from "assets/images/main_logo.svg";
import mapImage from "assets/images/map.jpg";
import hd01Webp from "assets/images/hd_01.webp";
import hd02Webp from "assets/images/hd_02.webp";
import hd03Webp from "assets/images/hd_03.webp";
import hd04Webp from "assets/images/hd_04.webp";
import hd05Webp from "assets/images/hd_05.webp";
import hd06Webp from "assets/images/hd_06.webp";
import hd07Webp from "assets/images/hd_07.webp";
import hd08Webp from "assets/images/hd_08.webp";
import aboutTitleImage from "assets/images/about_title.svg";
import seirigakuSetsumeiImage from "assets/images/seirigaku_setsumei.jpg";
import { LinkButton } from "components/LinkButton";
import Box from "@mui/material/Box";

// Rest of the code

const Parent01 = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(2),
  "& hr": {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const Parent02 = styled(Parent01)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "& hr": {
    borderColor: theme.palette.primary.main,
  },
  "& h3": {
    color: theme.palette.primary.main,
  },
  "& .caption": {
    ...theme.typography.caption,
    overflowWrap: "break-word",
  },
}));

const Headline = styled("h2")(({ theme }) => theme.typography.h2);

export default function Main() {
  return (
    <Smaphon2Col>
      <Kv />
      <Profile />
      <About />
      <Menu />
      <Reservation />
      <Map />
    </Smaphon2Col>
  );
}

function Kv() {
  const DivKv = styled("div")(({ theme }) => ({
    position: "relative",
    maxWidth: "380px",
    margin: "auto",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(4),
    "& img": {
      width: "100%",
      display: "block",
    },
    "& .comments": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
      alignItems: "center",

      "& .comment": {
        width: "50px",
        height: "auto",
        position: "absolute",
        animationDuration: "15s",
        animationFillMode: "backwards",
        animationIterationCount: "infinite",
        animationTimingFunction: "ease",
      },
    },
  }));

  const ImgComent01 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "28%",
    left: "20%",
    animation: "animaComment01",
    "@keyframes animaComment01": {
      "0%": {
        opacity: 0,
      },
      "10%": {
        opacity: 0,
      },
      "12%": {
        opacity: 1,
      },
      "18%": {
        opacity: 1,
      },
      "20%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent02 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "30%",
    left: "5%",
    animation: "animaComment02",
    "@keyframes animaComment02": {
      "0%": {
        opacity: 0,
      },
      "20%": {
        opacity: 0,
      },
      "22%": {
        opacity: 1,
      },
      "28%": {
        opacity: 1,
      },
      "30%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent03 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "27%",
    left: "5%",
    animationName: "animaComment03",
    "@keyframes animaComment03": {
      "0%": {
        opacity: 0,
      },
      "30%": {
        opacity: 0,
      },
      "32%": {
        opacity: 1,
      },
      "38%": {
        opacity: 1,
      },
      "40%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent04 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "30%",
    left: "20%",
    animationName: "animaComment04",
    "@keyframes animaComment04": {
      "0%": {
        opacity: 0,
      },
      "40%": {
        opacity: 0,
      },
      "42%": {
        opacity: 1,
      },
      "48%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent05 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "32%",
    left: "7%",
    animationName: "animaComment05",
    "@keyframes animaComment05": {
      "0%": {
        opacity: 0,
      },
      "50%": {
        opacity: 0,
      },
      "52%": {
        opacity: 1,
      },
      "58%": {
        opacity: 1,
      },
      "60%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent06 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "30%",
    left: "20%",
    animationName: "animaComment06",
    "@keyframes animaComment06": {
      "0%": {
        opacity: 0,
      },
      "60%": {
        opacity: 0,
      },
      "62%": {
        opacity: 1,
      },
      "68%": {
        opacity: 1,
      },
      "70%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent07 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "30%",
    left: "4%",
    animationName: "animaComment07",
    "@keyframes animaComment07": {
      "0%": {
        opacity: 0,
      },
      "70%": {
        opacity: 0,
      },
      "72%": {
        opacity: 1,
      },
      "78%": {
        opacity: 1,
      },
      "80%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const ImgComent08 = styled("img")(({ theme }) => ({
    //defImgComent,
    top: "30%",
    left: "22%",
    animationName: "animaComment08",
    "@keyframes animaComment08": {
      "0%": {
        opacity: 0,
      },
      "80%": {
        opacity: 0,
      },
      "82%": {
        opacity: 1,
      },
      "88%": {
        opacity: 1,
      },
      "90%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(-2em)",
      },
    },
  }));

  const Headline = styled("h1")(({ theme }) => ({
    maxWidth: "150px",
    objectFit: "contain",
    textAlign: "center",
    border: "unset",
    margin: "auto",
    "& .logo-img": {},
  }));

  const Catchcopy = styled("p")(({ theme }) => ({
    fontSize: "1.0rem",
    fontWeight: "100",
    letterSpacing: "0.3em",
    fontFamily: "M PLUS Rounded 1c, sans-serif",
    color: "rgb(112, 112, 112)",
    "& span": {
      textAlign: "center",
      display: "block",
    },
  }));

  return (
    <DivKv id="kv">
      <img src={mainImageWebp} alt="セラピーイメージ" />
      <div className="comments">
        <ImgComent01 className="comment" src={hd01Webp} alt="" />
        <ImgComent02 className="comment" src={hd02Webp} alt="" />
        <ImgComent03 className="comment" src={hd03Webp} alt="" />
        <ImgComent04 className="comment" src={hd04Webp} alt="" />
        <ImgComent05 className="comment" src={hd05Webp} alt="" />
        <ImgComent06 className="comment" src={hd06Webp} alt="" />
        <ImgComent07 className="comment" src={hd07Webp} alt="" />
        <ImgComent08 className="comment" src={hd08Webp} alt="" />
      </div>
      <Headline>
        <img className="logo-img" src={mainLogoImage} alt="LOGO" />
      </Headline>

      <Catchcopy>
        <span>からだも、こころも、健やかに</span>
        <span>元気処（げんきどころ）は</span>
        <span>貴方の元気をサポートします。</span>
      </Catchcopy>
      <Box sx={{ margin: "auto" }}>
        <LinkButton
          link="#Reservation"
          label="WEB予約"
          style={{
            color: theme.palette.primary.contrastText,
            backgroundColor: "rgb(245,130,32)",
          }}
        />
      </Box>
    </DivKv>
  );
}

function Profile() {
  const Item = styled("div")(({ theme }) => ({
    placeItems: "center",
    letterSpacing: "0.3em",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(1),
    "& h3": theme.typography.h3,
    "& .label": theme.typography.subtitle1,
    "& .name span:nth-of-type(1):after": {
      content: "'/'",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .caption":
      (theme.typography.caption, { "& span": { display: "block" } }),
  }));

  return (
    <Parent01 id="Profile">
      <Headline>
        <img src={profileTitleImage} alt="" />
      </Headline>
      <Item>
        <h3 className="name">
          <span>三好健太</span>
          <span>整体師</span>
        </h3>
      </Item>
      <hr />
      <Item>
        <h3 className="label">経歴</h3>
        <p className="caption">
          <span>整体歴10年以上、大阪の整体院勤務。</span>
          <span>現在は川崎をメインに活動中。</span>
          <span>
            名古屋にいた頃はプロスポーツ選手の試合帯同もしていました。
          </span>
        </p>
      </Item>
      <hr />
      <Item>
        <h3 className="label">ポリシー</h3>
        <p className="caption">
          体の気になるポイントはもちろん、その痛みの原因となる部位を見つけ根本から改善する手技を用いてもろもろの悩みに対応します。
        </p>
      </Item>
      <hr />
      <img src={roomImage} alt="プライベートな施術空間" />
    </Parent01>
  );
}

function About() {
  const Title01 = styled("h3")(({ theme }) => theme.typography.h3, {
    color: theme.palette.primary.main,
    textAlign: "center",
  });

  return (
    <Parent02 id="About">
      <Headline>
        <img src={aboutTitleImage} alt="" />
      </Headline>
      <Title01>効果が感じられる施術内容</Title01>
      <hr />
      <div className="caption">
        揉みほぐしを受けても疲れや痛み、むくみが取れないと感じたことはありませんか？
        それらは体の外の筋肉からくるのではなく、体の内側にある毒素から来ているかも！
        体の内側から整えて本当の楽な体を体験してみましょう！
      </div>
      <img
        className="img"
        src={seirigakuSetsumeiImage}
        alt="生理学説明イメージ"
      ></img>
    </Parent02>
  );
}

function Menu() {
  const Menus = {
    デトックス整体: [
      { name: "30分", money: "¥4,400-" },
      { name: "45分", money: "¥6,600-" },
      { name: "60分", money: "¥8,800-" },
    ],
    もみほぐし: [{ name: "60分", money: "¥4,950-" }],
  };

  const MenuCategory = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(2),
    "& h3": theme.typography.h3,
    "& .menu": {
      margin: "auto",
      fontSize: "1.5rem",
      position: "relative",
      display: "flex",
      gap: theme.spacing(0.5),
      justifyContent: "flex-end",
      flexFlow: "row",
      maxWidth: "420px",
      "& *": {
        backgroundColor: theme.palette.primary.main,
        zIndex: 2,
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
      },
    },
  }));

  const deviceType = useDeviceType();

  const MenuTen = () => {
    switch (deviceType) {
      case DeviceType.Desktop:
        return "・・・・・・・・・";
      case DeviceType.Tablet:
        return "・・・・・・・・・";
      default:
        return "・・・・";
    }
  };

  return (
    <Parent01 id="Menu">
      <Headline>
        <img src={menuTitleImage} alt="メニュー" />
      </Headline>

      {Object.entries(Menus).map(([category, menu]) => {
        return (
          <MenuCategory key={category}>
            <h3>{category}</h3>
            {menu.map((item, index) => {
              return (
                <div className="menu" key={index}>
                  <span className="name">{item.name}</span>
                  <span className="bg">{MenuTen()}</span>
                  <span className="value">{item.money}</span>
                </div>
              );
            })}
          </MenuCategory>
        );
      })}
    </Parent01>
  );
}

function Map() {
  const Parent01Custom = styled(Parent01)(({ theme }) => ({
    margin: "auto",
    padding: theme.spacing(2),
    width: "80%",
    maxWidth: "460px",
    boxSizing: "border-box",
    position: "relative",
    zIndex: 2,
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  }));

  const MapImg = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: MainWidth,
    position: "relative",
    top: `-${theme.spacing(4)}`,
    zIndex: 1,
    "& img": {
      width: "100%",
      display: "block",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      TextDecoderStreamDecoration: "none",
    },
  }));

  return (
    <Parent02 id="Map">
      <Parent01Custom>
        <Headline>
          <img src={syozaitiImage} alt="所在地"></img>
        </Headline>
        <Address />
      </Parent01Custom>
      <MapImg>
        <a
          href="https://maps.app.goo.gl/URNAdSLEfqAdWk6q7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mapImage} alt="元気処所在地" />
          ※クリックで地図が開きます
        </a>
      </MapImg>
    </Parent02>
  );
}

function Reservation() {
  // iframeの幅と高さを状態として管理
  const [iframeWidth, setIframeWidth] = useState("100%");
  const [iframeHeight, setIframeHeight] = useState("95vh");

  const BorderFrame = styled("div")(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    textAlign: "center",
  }));
  const IFrame = styled("iframe")(({ theme }) => ({
    margin: "0",
    border: "0",
    width: iframeWidth,
    height: iframeHeight,
  }));
  // iframe内のコンテンツのサイズを取得し、iframeのサイズを調整する関数
  /*
  const adjustIframeSize = () => {
    const iframe = document.getElementById(
      "ttnou-reservation"
    ) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      const contentWidth = iframe.contentWindow.document.body.scrollWidth;
      const contentHeight = iframe.contentWindow.document.body.scrollHeight;

      setIframeWidth(`${contentWidth}px`);
      setIframeHeight(`${contentHeight}px`);
    }
  };

  // コンポーネントがマウントされたときとコンテンツが更新されたときにサイズを調整
  useEffect(() => {
    adjustIframeSize();
  }, []);
  */

  console.log(process.env.REACT_APP_TTNOU_SCHEDULE_URL);
  console.log(process.env.REACT_APP_TTNOU_RESERVATION_FORM_URL);

  return (
    <Parent02 id="Reservation">
      <Headline>
        <img src={yoyakuTitle02Image} alt="" />
      </Headline>
      <BorderFrame>
        <IFrame
          id="ttnou-reservation"
          title="ttnou.com ゲスト予約機能"
          src={process.env.REACT_APP_TTNOU_SCHEDULE_URL}
          //onLoad={adjustIframeSize}
        ></IFrame>
        {/*
        <AnotherTabButton
          link={
            process.env.REACT_APP_TTNOU_RESERVATION_FORM_URL
              ? process.env.REACT_APP_TTNOU_RESERVATION_FORM_URL
              : ""
          }
          label="予約はコチラ"
          style={{
            color: theme.palette.primary.contrastText,
            backgroundColor: "rgb(245,130,32)",
          }}
        />
        */}
      </BorderFrame>
    </Parent02>
  );
}
